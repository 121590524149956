/**
 * App class main javascript class for default functionality
 * @author Sarwar Hossain <sarwar.me>
 * @link http://sarwar.me
 *
 */

INSTA.Lib = (function ($) {
    return {
        init: function(){
            INSTA.Lib.initDeleteConfirm();
            INSTA.Lib.clearText();

        },

        formSubmitAjax: function(theForm, settings){

            var error = $('.alert-danger', theForm);
            theForm.validate({
                errorElement: 'span', //default input error message container
                errorClass: 'help-block', // default input error message class
                focusInvalid: false, // do not focus the last invalid input
                ignore: ":hidden,.ignore",  // validate all fields including form hidden input
                //rules: rules,

                invalidHandler: function (event, validator) { //display error alert on form submit
                        error.show();
                       INSTA.App.scrollTo(error, -200);
                },

                highlight: function (element) { // hightlight error inputs
                    $(element)
                        .closest('.form-group').addClass('has-error'); // set error class to the control group
                },

                unhighlight: function (element) { // revert the change done by hightlight
                    $(element)
                        .closest('.form-group').removeClass('has-error'); // set error class to the control group
                },

                success: function (label) {
                    label
                        .closest('.form-group').removeClass('has-error'); // set success class to the control group

                },
                submitHandler: function (form) {

                        error.hide();
                        var config = {
                            resetForm: false,
                            data:undefined,
                            beforeSubmit:function ($form, config) {

                            },
                            beforeSend: function(xhr, $form){
                                if ( typeof _csrfToken !== 'undefined' ) {
                                    xhr.setRequestHeader('X-CSRF-Token', _csrfToken);
                                }

                            },

                            success:undefined
                        };
                        if (typeof settings != 'undefined') {
                            $.extend(config, settings);
                        }
                        $(form).ajaxSubmit({
                            resetForm: config.resetForm,
                            data: config.data,
                            dataType: settings.dataType ? settings.dataType : 'json',
                            beforeSend: function(xhr){

                                if (typeof config.beforeSend != 'undefined') {
                                        config.beforeSend(xhr);
                                }

                            },
                            beforeSubmit:function ($form, config) {
                                if (typeof config.beforeFormSubmit != 'undefined') {
                                    config.beforeSubmit($form, config);
                                }
                                if(typeof form != 'undefined'){
                                     INSTA.App.blockUI({
                                        target : form,
                                        message: "Processing..Please wait!!"
                                    });
                                }
                            },
                            success:function (resp, statusText, xhr) {
                                if (typeof config.success != 'undefined') {
                                    config.success(resp, $(form));
                                }
                            },
                            complete:function() {
                              INSTA.App.unblockUI(form);
                            },
                            error: function(resp){
                                var json = resp.responseJSON;
                                if(json){
                                     INSTA.Lib.iniToastrNotification("error", "Error: "+json.code, json.message);
                                }



                            }
                        });
                    return false;

                }
            });
        },
        formSubmit: function(theForm, settings){
            var error = $('.alert-danger', theForm);
            $(theForm).validate({
                errorElement: 'span', //default input error message container
                errorClass: 'help-block', // default input error message class
                focusInvalid: false, // do not focus the last invalid input
                validClass: "help-inline",
                ignore: ":hidden,.ignore",  // validate all fields including form hidden input
                //rules: rules,

                invalidHandler: function (event, validator) { //display error alert on form submit
                    error.show();
                    INSTA.App.scrollTo(error, -50);
                },

                highlight: function (element) { // hightlight error inputs
                    $(element)
                        .closest('.form-group').addClass('has-error'); // set error class to the control group
                },

                unhighlight: function (element) { // revert the change done by hightlight
                    $(element)
                        .closest('.form-group').removeClass('has-error'); // set error class to the control group

                        if($(element).next().hasClass("help-block")){
                            $(element).next().remove();
                        }

                },

                success: function (label) {
                    label
                        .closest('.form-group').removeClass('has-error'); // set success class to the control group

                },
                submitHandler: function (form) {
                        if (settings && typeof settings.submitHandler != 'undefined') {
                           settings.submitHandler(form);
                        }else{
                            INSTA.App.blockUI({
                                target : form,
                                message: "Processing..Please wait!!"
                            });
                            form.submit();
                            return true;
                        }

                }


            });

        },
        /**
         *
         * @param {type} form
         * @param {type} wizardId
         * @param {type} progressBar
         * @param {type} isAjax
         * @param {type} ajaxConfig
         * @param {type} wizardSettings : overwrite the existing seettings
         * @returns {undefined}
         */
        formWizard: function(formId, wizardId, progressBar, isAjax, ajaxConfig, wizardSettings){
            $(wizardId).find('.button-submit').show();
            if(!wizardSettings) wizardSettings = null;
            if(!wizardId) wizardId = "#formWizard";
            if(!progressBar) progressBar = false;
            var form = $(formId);
            if(isAjax){
                INSTA.Lib.formSubmitAjax(form, ajaxConfig);
            }else{
                INSTA.Lib.formSubmit(form);
            }


            var error = $('.alert-danger', form);
            var success = $('.alert-success', form);

            var handleTitle = function(tab, navigation, index) {
                var total = navigation.find('li').length;
                var current = index+1;
                var aObj = tab.find("a");

                // set done steps
                jQuery('li', $(wizardId)).removeClass("done");
                var li_list = navigation.find('li');
                for (var i = 0; i < index; i++) {
                    jQuery(li_list[i]).addClass("done");
                }

                if (current == 1) {
                    $(wizardId).find('.button-previous').hide();
                } else {
                    $(wizardId).find('.button-previous').show();
                }

                if (current >= total) {
                    $(wizardId).find('.button-next').hide();
                } else {
                    $(wizardId).find('.button-next').show();
                    // $(wizardId).find('.button-submit').hide();
                }
                INSTA.App.scrollTo($('.form-wizard'), -100);
            }

            // default form wizard
            $(wizardId).bootstrapWizard({
                'nextSelector': '.button-next',
                'previousSelector': '.button-previous',
                onTabClick: function (tab, navigation, index, clickedIndex) {

                    success.hide();
                    error.hide();

                    if ($(formId).valid() == false) {
                        return false;
                    }

                    if (wizardSettings && typeof wizardSettings.onTabClick != 'undefined') {
                            if(wizardSettings.onTabClick(tab, navigation, index, clickedIndex) == false){
                                return false;
                            }
                    }

                    handleTitle(tab, navigation, clickedIndex);
                },
                onNext: function (tab, navigation, index) {
                    success.hide();
                    error.hide();

                    if ($(formId).valid() == false) {
                        return false;
                    }

                    if (wizardSettings && typeof wizardSettings.onNext != 'undefined') {
                            if(wizardSettings.onNext(tab, navigation, index) == false){
                                return false;
                            }
                    }

                    handleTitle(tab, navigation, index);
                },
                onPrevious: function (tab, navigation, index) {
                    success.hide();
                    error.hide();

                    if (wizardSettings && typeof wizardSettings.onPrevious != 'undefined') {
                            wizardSettings.onPrevious(tab, navigation, index);
                    }

                    handleTitle(tab, navigation, index);
                },
                onTabShow: function (tab, navigation, index) {
                    var total = navigation.find('li').length;
                    if(progressBar){
                        var current = index + 1;
                        var $percent = (current / total) * 100;
                        $(wizardId).find('.progress-bar').css({
                            width: $percent + '%'
                        });
                    }
                    if (wizardSettings && typeof wizardSettings.onTabShow != 'undefined') {
                            wizardSettings.onTabShow(tab, navigation, index);
                    }

                    handleTitle(tab, navigation, index);
                }
            });

            $(wizardId).find('.button-previous').hide();
            $(wizardId+' .button-submit').click(function () {
                form.submit();
            }).show();
        },

         /**
         *  used for general ajax call method in this cms .
         *    @param {settings} an object property
         *    @cfg {settings.url} a string type valid url
         *    @cfg {settings.data} an object type data that act as server request type data parameter
         *    @cfg {settings.type} a string type server request.[get,post,put,delete].default post
         *    @cfg {settings.afterSuccess} a custom event function which is invoked when server return a response.
         *    @cfg {settings.error} a custom event function which is invoked when server return a error or no response.
         *    @cfg {settings.beforeSend} a custom event function which is invoked before sending request.
         *    @cfg {settings.complete} a custom event function which is invoked when all process complete.
         *    @return void
         */
        callAjax: function (settings) {
            var config = {
                url: '',
                data: {},
                dataType: 'html',
                type: 'post',
                notificationMessage: "Loading..",
                contentType: "application/x-www-form-urlencoded;charset=UTF-8",
                success: function (data) {
                },
                error: function (data) {

                },
                beforeSend: function (data) {
                  //  bootbox.alert("Loading..");
                },
                complete: function (data) {
                  //  bootbox.alert("Loading..");
                }
            };
            if (settings) {
                $.extend(config, settings);
            }
            $.ajax({
                type: config.type,
                url: config.url,
                data: config.data,
                cache: false,
                contentType: config.contentType,
                dataType: config.dataType,
                success: function (data) {
                    config.success(data);
                },
                error: function (data) {
                    config.error(data);
                },
                beforeSend: function (data) {
                    config.beforeSend(data);
                },
                complete: function (data) {
                    config.complete(data);
                }
            })
        },

        inputBoxMask: function (elm, type) {

            if(!elm) elm = ".input-mask";

            if(!type){
                $(".mask-date").inputmask("mm/dd/yyyy", {
                    autoUnmask: true
                }); //direct mask

                $(".mask-phone").inputmask("mask", {
                    "mask": "(999) 999-9999"
                }); //specifying fn & options

                $(".mask-tin").inputmask({
                    "mask": "99-9999999"
                }); //specifying options only
                $(".mask-number").inputmask({
                    "mask": "9",
                    "repeat": 10,
                    "greedy": false
                }); // ~ mask "9" or mask "99" or ... mask "9999999999"
                $(".mask-decimal").inputmask('decimal', {
                    rightAlignNumerics: false
                }); //disables the right alignment of the decimal input
                $(".mask-currency").inputmask('€ 999.999.999,99', {
                    numericInput: true
                }); //123456  =>  € ___.__1.234,56

                $(".mask-currency-decimal").inputmask('€ 999,999,999.99', {
                    numericInput: true,
                    rightAlignNumerics: false,
                    greedy: false
                }); //123456  =>  € ___.__1.234,56
                $(".mask-ssn").inputmask("999-99-9999", {
                    placeholder: " ",
                    clearMaskOnLostFocus: true
                }); //default

                $(elm).inputmask();
            }else{
                if(type == "date"){
                    $(elm).inputmask("mm/dd/yyyy", {
                        autoUnmask: true
                    });
                }else if(type == "phone"){
                    $(elm).inputmask("mask", {
                       "mask": "(999) 999-9999"
                   });
                }else if(type == "tin"){
                    $(elm).inputmask({
                        "mask": "99-9999999"
                    }); //specifying options only

                }
            }

        },
        printSingleDiv: function(elm, cssFile){

            if(!elm) elm = '.print-div-container';
            var css = "";
            if(cssFile){
                $.each(cssFile, function(i,data){
                   css += "<link href='"+data+"' rel='stylesheet' type='text/css' />";
                });
            }
            var html = '<html><head>' +
               css +
               '</head><body style="background:#ffffff;">'+
               $(elm).html() +
               '</body></html>';

            var WindowObject = window.open("", "PrintWindow",
            "width=960,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes");
            WindowObject.document.writeln(html);
            WindowObject.document.close();
            WindowObject.focus();
            WindowObject.print();
            WindowObject.close();
        },
        iniToastrNotification: function(type, title, msg) {
            toastr.options = {
                "closeButton": true,
                "debug": false,
                "positionClass": "toast-top-right",
                "onclick": null,
                "progressBar" : true,
                "showDuration": "300",
                "hideDuration": "1000",
                "timeOut": "9500",
                "extendedTimeOut": "5000",
                "showEasing": "swing",
                "hideEasing": "linear",
                "showMethod": "fadeIn",
                "hideMethod": "fadeOut"
            };
            if (type == "warning") {
                toastr.warning(msg, title);
            } else if (type == "error") {
                toastr.error(msg, title);
            } else if (type == "success") {
                toastr.success(msg, title);
            } else {
                toastr.info(msg, title);
            }
        },
        initSummarNote: function(ele, settings) {
            if (!ele)
                ele = ".summarnote";
            var config = {
                height: 300
            };
            if (!settings)
                $.extend(config, settings);
            $(ele).summernote(config);
        },
        bindRemoveParent: function(ele) {
            if (!ele)
                ele = ".remove-more-item";
            $(ele).on("click", function() {
                $(this).parent().remove();
            });
        },
        /**
         *  Global Delete method after delete hide the table row
         * @param {type} type
         * @param {type} elem
         * @param {type} msg
         * @returns {undefined}
         */
        deleteItem: function($panel, elem, msg) {
            if (!msg)
                msg = "Are you sure?";
            $panel.find(elem).on("click", function(event) {
                var href = $(this).attr("href");
                var trId = $(this).attr("data-tr");
                bootbox.confirm(msg, function(result) {
                    if (result == true) {
                        INSTA.Lib.callAjax({
                            url: href,
                            dataType: "json",
                            beforeSend: function() {
                                $panel.find("#" + trId).addClass("bdTr");
                            },
                            success: function(data) {
                                if (data.result == true) {
                                    INSTA.Lib.iniToastrNotification("success", "Success", data.message);
                                    $panel.find("#" + trId).remove();
                                } else {
                                    INSTA.Lib.iniToastrNotification("error", "Failed", data.message);
                                    $panel.find("#" + trId).removeClass("bdTr");
                                }
                            },
                            complete: function() {
                            }
                        })
                    }
                });
                event.preventDefault();
            })
        },
        /**
         * Animated effict when ajax callin gor just focus somthing
         * @param {type} elem
         * @param {type} settings (user can put all confing for this plugin)
         * @returns {undefined}
         *
         */
        pulsateBind: function(elem, settings) {
            var config = {
                color: "#fbde41",
                repeat: true
            };
            if (typeof settings != 'undefined') {
                $.extend(config, settings);
            }
            if (!elem)
                elem = ".pulsate-now";
            $(elem).pulsate(config);
        },
        bindSelect2Depend: function(elem, jsonData) {
            if (!elem)
                elem = ".select-depend";
            $(elem).on("change", function() {
                var item = $(this).attr("data-dataSource");
                var id = $(this).val();
                var childId = "#" + $(this).attr("data-child-id");
                INSTA.Lib.generateSelect2Data(childId, jsonData[item][id]);
            });
        },
        initDeleteConfirm: function(ele) {
            if (!ele)
                ele = ".deleteLink";
            $(ele).unbind().on("click", function(event) {
                var thisObj = this;
                var msg = $(this).attr("data-msg");
                if (!msg)
                    msg = "Are you sure?";
                bootbox.confirm(msg, function(result) {
                    if (result) {
                        window.location = thisObj.href;
                    }
                    else {
                    }
                });
                event.preventDefault();
            });
        },
        bindAddMore: function(ele) {
            ele = ele || ".add-more-cls";
            $(ele).on("click", function() {
                var sourceDiv = $(this).attr("data-source-div");
                var appendDiv = $(this).attr("data-append-div");
                //select2:: addmore
//                if ($(this).find('.select2me')) {
//                    $('.select2me').select2('destroy');
//                }
                $(appendDiv).append($(sourceDiv).html());
                //select2:: addmore
//                if ($(this).find('.select2me')) {
//                    $('.select2me').select2();
//                }
                $(".remove-more-item").on("click", function() {
                    if ($(this).parent().parent().hasClass("source-item") == false) {
                        $(this).parent().remove();
                    }
                });
                INSTA.Lib.inputBoxMask();
            });
            $(".remove-more-item").on("click", function() {
                if ($(this).parent().parent().hasClass("source-item") == false) {
                    $(this).parent().remove();
                }
            });
        },
        removeItemByAttr: function(ele) {
            if (!ele)
                ele = ".remove-attar-item";
            $(ele).on("click", function() {
                var removeItem = $(this).attr("data-remove-id");
                $("#" + removeItem).remove();
            });
        },
        initPortletTitle: function() {
            $('body').on('click', '.portlet > .portlet-title.toggle-portlet-off, .portlet .portlet-title.toggle-portlet-on', function(e) {
                e.preventDefault();
                var el = $(this).closest(".portlet").children(".portlet-body");
                if ($(this).hasClass("toggle-portlet-off")) {
                    $(this).removeClass("toggle-portlet-off").addClass("toggle-portlet-on");
                    $(this).find('.tools > a.collapse').removeClass("collapse").addClass("expand");
                    el.slideUp(200);
                } else {
                    $(this).removeClass("toggle-portlet-on").addClass("toggle-portlet-off");
                    $(this).find('.tools > a.expand').removeClass("expand").addClass("collapse");
                    el.slideDown(200);
                }
            });
        },
         ajaxModal: function(settings){
            var config = {
                    href : $(".ajaxModal").attr("data-modal-href"),
                    modalObj: $(".ajajModal"),
                    dataType: 'html',
                    type: 'post',
                    beforeSend: function(){},
                    success: function(){}
            };

            if (typeof settings != 'undefined') {
                $.extend(config, settings);
            }
            INSTA.Lib.callAjax({
                    url: config.href,
                    dataType: config.dataType,
                    type: config.type,
                    beforeSend: function(){
                        if (typeof config.beforeSend != 'undefined') {
                             config.beforeSend(config);
                         }else{
                            config.modalObj.find(".modal-content .modal-header h4").html("");
                            config.modalObj.find(".modal-content .modal-body").html("");
                         }
                        INSTA.App.blockUI({
                            animate: true

                        });
                    },
                    success: function(data){
                        if (typeof config.success != 'undefined') {
                            config.success(data);
                        }else{
                            if(config.dataType == 'json'){
                                config.modalObj.find(".modal-content").html(data.data);
                            }else{
                                config.modalObj.find(".modal-content").html(data);
                            }

                        }
                        config.modalObj.modal("show");
                    },
                    complete: function(){
                       INSTA.App.unblockUI();
                    }
            });
        },
         /* This method used for checkbox show hide. If checkbox is checked
          * It shwo the div
          * Rules:
          *     Checkbox Id or Class: checkBox  (Main action)
          *     Show/Hide Div: a data option on Chexkbox
          *         "data-togger-div-class"
          * @param TabSectioNname type
          * @param Text formId
          * @param Text-CheckboxID or Class:  checkBox
          * @returns {undefined}
          */
         checkBoxTroggle: function(tab, formId, checkBox){

             tab.find(formId +" "+checkBox).on("click", function(){
                var divClass = $(this).attr("data-togger-div-class");
                if($(this).is(":checked")){
                    tab.find(formId+" "+ divClass).show();
                }else{
                    tab.find(formId+" "+ divClass).hide();
                }
            });
         },
         /* This method used when checkbox is checked it showing one dive
          * and hide another div
          * Rules:
          *     Checkbox Id: checkboxId  (Main action)
          *     Show Div: checkboxId-div (CSS Class name)
          *     Hide Div: checkboxId-div-hide (Css Class naame)
          * @param TabSectioNname type
          * @param Text formId
          * @param Text-Without-#/. checkBoxId
          * @returns {undefined}
          */
         checkBoxDivShowHide: function(tab, formId, checkBoxId){
            tab.find(formId +" #"+checkBoxId).on("click", function(){
                if($(this).is(":checked")){
                    tab.find(formId+" ."+ checkBoxId +"-div").show();
                    tab.find(formId+" ."+ checkBoxId +"-div-hide").hide();
                }else{
                    tab.find(formId+" ."+ checkBoxId +"-div").hide();
                    tab.find(formId+" ."+ checkBoxId +"-div-hide").show();
                }
            });
         },

        fileBrowseButton: function(mEle){
            $(mEle).find("a#browseBtn").on("click", function(){
                $(mEle).find("input#uploadFile").trigger("click");
            });
            $(mEle).find("input#uploadFile").on("change", function(){
                $(mEle).find("div#showFineName").html("File: "+$(this).val());
            });
        },
           /**
         * date picker common function
         * @param elm
         * @param _options
         */
        datePicker: function(elm, _options) {
             if(!elm) elm = ".date-picker";
            var options = {
                dateFormat: 'mm/dd/yy',
                changeMonth: true,
                changeYear: true,
                yearRange: "-120:+30"
            };
            if (_options) {
                $.extend(options, _options);
            }

            $(elm).datepicker(options);
        },


           /**
         * color picker
         * @param elm
         * @param _options
         */
        colorPicker: function(elm, _options) {
             if(!elm) elm = ".minicolors";
            var options = {
                animationSpeed: 50,
                animationEasing: 'swing',
                change: null,
                changeDelay: 0,
                control: 'hue',
                defaultValue: '',
                format: 'hex',
                hide: null,
                hideSpeed: 100,
                inline: false,
                keywords: '',
                letterCase: 'lowercase',
                opacity: false,
                position: 'bottom left',
                show: null,
                showSpeed: 100,
                theme: 'default',
                swatches: []
              };
            if (_options) {
                $.extend(options, _options);
            }

            $(elm).minicolors(options);
        },

           /**
         * Time picker common function
         * @param elm
         * @param _options
         */
        timePicker: function(elm, _options) {
             if(!elm) elm = ".time-picker";
            var options = {
                minuteStep: 5,
                showInputs: false,
                disableFocus: true

            };
            if (_options) {
                $.extend(options, _options);
            }

            $(elm).timepicker(options);
        },

        clearText: function(elm){
            if(!elm){
                elm = '.clear-text'
            }
            $(elm).on("click", function(){
                var textBoxId = $(this).attr("data-id");
                $("#"+textBoxId).val("");
            })
        }

    }

}(jQuery));
$(function(){
   INSTA.Lib.init();
});